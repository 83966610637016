import { postLogDetails } from "../Services/APIs/appDetails"

export const detectCameras = async (uid = "uid not passed", app = "not provided") => {
    try {
        await navigator.mediaDevices.getUserMedia({ video: true })
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(device => device.kind === "videoinput")
        postLogDetails({ payLoad: `found ${videoDevices.length} camera device's`, uid, app })
        await Promise.all(videoDevices.map(async (device, i) => {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: device.deviceId }
            })
            const track = stream.getVideoTracks()[0]
            const capabilities = track.getCapabilities()
            postLogDetails({ payLoad: `camera no:${i + 1}: ${capabilities.facingMode}, label : ${device.label}`, uid, app })
            track.stop()
            return 1
        }))

        localStorage.setItem("tkcmtd", JSON.stringify(true))
    } catch (error) {
        postLogDetails({ payLoad: error.message, uid, app})
    }
}